import React from "react";
import { Polar } from "react-chartjs-2";

const TopsChart = (props) => {
  const {arr} = props;
  const data = {
    labels: arr.map(key => key.name),
     datasets: [
        {
            data: arr.map(key => key.USD),
            borderWidth: 0,
            backgroundColor: [
                "#84e8e5",
                "#b183e0",
                "#7adc00",
                "#84ff95",
                "#00a2ff",
            ],
        },
     ],
  };

  return <Polar className="home_polar_chart" data={data} options={{
    responsive: true,
    maintainAspectRatio: false,
    legend: false,
    tooltips: {

    }
  }}/>;
 
};

export default TopsChart;
