import React from 'react';
import './loading.css';

function Loading () {

    return(
        <div className="loading">
            <div className="loader">
                <div className="face">
                    <div className="circle">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading;